<template>
  <the-navigation />
  <router-view />
</template>

<script>
  import TheNavigation from './components/TheNavigation.vue'
  /*import {
    MDBTabs,
    MDBTabNav,
    MDBTabContent,
    MDBTabItem,
    MDBTabPane,
    MDBContainer
  } from 'mdb-vue-ui-kit';
  import { ref } from 'vue';*/
  export default {
    components: {
      TheNavigation
      /*MDBTabs,
      MDBTabNav,
      MDBTabContent,
      MDBTabItem,
      MDBTabPane,
      MDBContainer*/
    },
    setup() {
      /*const activeTabId4 = ref('ex4-1');

      return {
        activeTabId4,
      };*/
    },
  };
</script>

<style lang="scss">
@import "~@/../mdb/scss/index.free.scss";

#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}
</style>
