<template>
  <MDBNavbar expand="lg" light bg="light" container class="fixed-top">
      <MDBNavbarBrand href="./">
      <img
        src="../assets/images/StanceLogo.png"
        height="30"
        alt="MDB Logo"
        loading="lazy"
      />
      MATH STANCE
    </MDBNavbarBrand>
    <!-- Toggle button -->
    <MDBNavbarToggler
      target="#navbarRightAlignExample"
      @click="collapse5 = !collapse5"
    ></MDBNavbarToggler>
    <!-- Collapsible wrapper -->
    <MDBCollapse v-model="collapse5" id="navbarRightAlignExample">
      <MDBNavbarNav right class="mb-2 mb-lg-0">
        <!-- Right links -->
        <MDBDropdown class="nav-item" v-model="dropHigh">
          <MDBDropdownToggle
            tag="a"
            class="nav-link"
            @click="dropHigh = !dropHigh"
            >High School</MDBDropdownToggle
          >
          <MDBDropdownMenu>
            <MDBDropdownItem href="#">Mathematics</MDBDropdownItem>
            <MDBDropdownItem href="#">Advanced Programme Mathematics</MDBDropdownItem>
            <MDBDropdownItem href="#">Physical Science</MDBDropdownItem>
            <MDBDropdownItem href="#">Engineering Graphics</MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
        <MDBNavbarItem href="./contact" active>
          Contact
        </MDBNavbarItem>
        <!-- Navbar dropdown -->
        <MDBDropdown class="nav-item" v-model="dropdown8">
          <MDBDropdownToggle
            tag="a"
            class="nav-link"
            @click="dropdown8 = !dropdown8"
            >Differential Equations</MDBDropdownToggle
          >
          <MDBDropdownMenu>
            <MDBDropdownItem href="./variable-separable">Variable Separable</MDBDropdownItem>
            <MDBDropdownItem href="#">Homogeneous</MDBDropdownItem>
            <MDBDropdownItem href="/first-order-linear-differential-equation">Linear</MDBDropdownItem>
            <MDBDropdownItem href="#">Exact</MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
        <MDBNavbarItem
          to="#"
          disabled
          tabindex="-1"
          aria-disabled="true"
        >
          Login
        </MDBNavbarItem>
        <!-- Right links -->
      </MDBNavbarNav>
    </MDBCollapse>
    <!-- Collapsible wrapper -->
  </MDBNavbar>
</template>

<script>
  import {
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBCollapse,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem
  } from 'mdb-vue-ui-kit';
  import { ref } from 'vue';

  export default {
    components: {
      MDBNavbar,
      MDBNavbarToggler,
      MDBNavbarBrand,
      MDBNavbarNav,
      MDBNavbarItem,
      MDBCollapse,
      MDBDropdown,
      MDBDropdownToggle,
      MDBDropdownMenu,
      MDBDropdownItem
    },
    setup() {
      const collapse5 = ref(false);
      const dropdown8 = ref(false);
      const dropHigh = ref(false);

      return {
        collapse5,
        dropdown8,
        dropHigh
      }
    }
  };
</script>

<style lang="scss" scoped>
@import '~mdb-ui-kit/css/mdb.min.css';
</style>